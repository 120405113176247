import React, { useState } from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { polyfill } from "seamless-scroll-polyfill";
import {
  NavbarPC,
  NavbarMobile,
  Footer,
  MetaDataHead,
  ScrollToTop, DMM,
} from "../../components";
import { Loading, AgeVerification, CharacterList } from "../../views";
import { useLoaded, useAgeConfirmed, useRedirectByLang } from "../../hooks";
import { LANGS_MAP_FOR_ASSETS } from "../../utils/languages";
import switchNormal from "../../assets/images/anr-btn.png";
import switchAnother from "../../assets/images/anr-btn2.png";
import {
  TopLeftLogo,
  CharacterHeader,
  CharacterPageContainer,
  CharacterContent,
  CharacterContentInner,
  CharacterIntro,
  CharacterAnimation,
  CharacterSlogan,
  CharacterSwitchBtn,
} from "./style";

if (typeof window !== "undefined") polyfill();

export default function CharacterPage({
  data, // this prop will be injected by the GraphQL query below.
  // pageContext,
}) {
  useRedirectByLang();

  const { markdownRemark } = data; // data.markdownRemark holds the character data
  const { frontmatter, html } = markdownRemark;
  const {
    title,
    occupation,
    weight,
    height,
    bwh,
    outerGIF,
    innerGIF,
    sloganImg,
  } = frontmatter;

  const intl = useIntl();
  const { locale, formatMessage } = intl;
  const lang = LANGS_MAP_FOR_ASSETS[locale];

  const { ageConfirmed, handleConfirm, handleDecline } = useAgeConfirmed();
  const { loaded } = useLoaded();

  const [isAnother, setIsAnother] = useState(false);

  const handleToHome = () => {
    window.location.href = `/`;
  };

  return (
    <>
      {<Loading style={loaded ? { opacity: 0, zIndex: "-40" } : { opacity: 1, zIndex: "40" }} />}
      {!ageConfirmed ? (
        <AgeVerification handleConfirm={handleConfirm} handleDecline={handleDecline} />
      ) : (
        <>
          <MetaDataHead>
            <link
              rel="preload"
              href={`${process.env.GATSBY_ROOT_PATH}/char-page-bg.jpg`}
              as="image"
            />
          </MetaDataHead>
          <CharacterPageContainer id="character-container">
            <DMM />
            <NavbarPC toRoot={true} />
            <NavbarMobile toRoot={true} />


            <TopLeftLogo id="back-to-home" onClick={handleToHome}>
              <img src={require(`../../assets/images/sub-logo-${lang}.png`).default} alt="logo" />
            </TopLeftLogo>

            <CharacterContent>
              <CharacterAnimation>
                <picture>
                  <source
                    srcSet={
                      require(`../../assets/images/characterList${
                        isAnother ? innerGIF[0] : outerGIF[0]
                      }`).default
                    }
                    type="image/webp"
                  />
                  <img
                    className="character-animation"
                    src={
                      require(`../../assets/images/characterList${
                        isAnother ? innerGIF[1] : outerGIF[1]
                      }`).default
                    }
                    alt="character"
                  />
                </picture>
                <CharacterSwitchBtn
                  onClick={() => {
                    setIsAnother(prev => !prev);
                  }}
                  img={isAnother ? switchAnother : switchNormal}
                  size={isAnother ? "small" : "large"}
                />
                <picture>
                  <source
                    srcSet={
                      require(`../../assets/images/characterListWebp${sloganImg.replace(
                        ".png",
                        ".webp"
                      )}`).default
                    }
                    type="image/webp"
                  />
                  <CharacterSlogan
                    src={require(`../../assets/images/characterList${sloganImg}`).default}
                    alt="character-preview"
                  />
                </picture>
              </CharacterAnimation>

              <CharacterContentInner>
                <CharacterHeader>
                  <div>
                    <h1 className={title.length > 9 ? "character__header--long" : ""}>{title}</h1>
                    <h3>{occupation}</h3>
                  </div>
                </CharacterHeader>
                <CharacterIntro>
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                  <ul>
                    <li>
                      {formatMessage({ id: "char-height" })}：{height}
                    </li>
                    <li>
                      {formatMessage({ id: "char-weight" })}：{weight}
                    </li>
                    <li>
                      {formatMessage({ id: "char-bwh" })}：{bwh}
                    </li>
                  </ul>
                </CharacterIntro>
              </CharacterContentInner>
            </CharacterContent>

            <CharacterList />
          </CharacterPageContainer>
          <Footer />

          <ScrollToTop />
        </>
      )}
    </>
  );
}

// query markdown by slug in frontmatter
// TODO: not sure why still need to do query here to get content by language...
export const characterQuery = graphql`
  query($slug: String!, $language: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }, fields: { lang: { eq: $language } }) {
      html
      frontmatter {
        type
        slug
        characterType
        order
        title
        occupation
        height
        weight
        bwh
        thumbImg
        outerGIF
        innerGIF
        sloganImg
      }
    }
  }
`;
